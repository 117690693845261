import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/about.css";
import Slider from "react-slick";
import {
	aboutpageimg,
	storyimg,
	sue1,
	sue2,
	team1,
	team2,
	team3,
	team4,
	visionimg,
	backArrow,
} from "../../constant";
import GoTop from "../../components/GoTop";
import {
	useAboutusPageQuery,
	useEmployeQuery,
	useHomePageQuery,
} from "../../store/services/HomeServices";
import Loader from "../../components/Loader";
import Backhistory from "../../components/Backhistory";

const About = () => {
	const { data: logoData } = useHomePageQuery();
	const { data: employe } = useEmployeQuery();
	const Homelogoicons = logoData?.data?.headerlogo?.image_url;
	const { data, isLoading } = useAboutusPageQuery();
	const aboutContent = data?.data?.content;
	const aboutTeam = data?.data?.teams;
	const companyLogos = data?.data?.logos;
	const employeData = employe?.data;
	console.log(employeData, "employe");

	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	// const employeeSettings = {
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 2,
	// 	initialSlide: 0,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1024,
	// 			settings: {
	// 				slidesToShow: 3,
	// 				slidesToScroll: 3,
	// 				infinite: true,
	// 				dots: true,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 600,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 2,
	// 				initialSlide: 2,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 480,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 	],
	// };

	const employeeSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 2,
		initialSlide: 0,
		centerMode: true, // Enables centering the slides with padding
		centerPadding: "20px", // Adds padding between the slides
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
					centerMode: true,
					centerPadding: "15px", // Adjust the padding on smaller screens
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
					centerMode: true,
					centerPadding: "10px",
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "5px",
				},
			},
		],
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<GoTop />
			<Header />
			{/* About page header starts here */}
			<section className="about-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="about-page-header-content-wrapper">
									<h1>About</h1>
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About page header ends here */}
			{/* our mission starts here */}
			<section className="our-mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img
										src={aboutContent?.section_1_image_url}
										className="img-fluid"
										alt="About us Image"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>{aboutContent?.section_1_title}</h2>
								<p>{aboutContent?.section_1_description}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* our vision starts here */}
			<section className="our-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>{aboutContent?.section_2_title}</h2>
								<p>{aboutContent?.section_2_description}</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img
										src={aboutContent?.section_2_image_url}
										className="img-fluid"
										alt="About us Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}

			{/* our story starts here */}
			<section className="our-story">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>
					<div className="row my-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="story-heading-wrapper">
								<h2>{aboutContent?.section_3_title}</h2>
							</div>
							<div className="story-card-wrapper">
								<div className="story-content-wrapper">
									<p>{aboutContent?.section_3_description}</p>
								</div>
								<div className="story-img-wrapper">
									<figure>
										<img
											src={aboutContent?.section_3_image_url}
											className="img-fluid"
											alt="About us Image"
										/>
									</figure>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our story ends here */}
			{/* our mission starts here */}
			<section className="our-mission mt-5 pt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-left">
								<figure>
									<img
										src={aboutContent?.section_4_image_url}
										className="img-fluid"
										alt="About us Image"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>{aboutContent?.section_4_title}</h2>
								<p>{aboutContent?.section_4_description}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our mission ends here */}
			{/* our vision starts here */}
			<section className="our-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>{aboutContent?.section_5_title}</h2>
								<p>{aboutContent?.section_5_description}</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img
										src={aboutContent?.section_5_image_url}
										className="img-fluid"
										alt="About us Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}
			{/* MOONSCAPE team starts here */}
			{/* <section className="moonscape-team">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>

					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="teams-main-heading-wrapper">
								<h2>Meet Our Team</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{aboutTeam?.map((item, index) => {
							return (
								<div className="col-lg-3" key={index}>
									<div className="team-card-wrapper">
										<div className="team-img-wrapper">
											<figure>
												<img
													src={item?.image_url}
													className="img-fluid"
													alt="About us Image"
												/>
											</figure>
										</div>
										<div className="team-content-wrapper">
											<h4>{item?.name}</h4>
											<p
												dangerouslySetInnerHTML={{
													__html: item?.description,
												}}
											></p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section> */}
			<section className="moonscape-team">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="divider-wrapper">
								<div className="divider-line"></div>
							</div>
						</div>
					</div>

					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="teams-main-heading-wrapper text-start">
								<h2>{aboutContent?.section_6_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: aboutContent?.section_6_description,
									}}
								></p>
							</div>
						</div>
					</div>

					{/* <div className="logo_slider"></div> */}

					{/* <div className="container mb-lg-5">
						<div className="row">
							<div className="col-lg-12">
								<div className="divider-wrapper">
									<div className="divider-line"></div>
								</div>
							</div>
						</div>

						<div className="teams-main-heading-wrapper">
							<h2>Our Employe</h2>
						</div>
						<Slider {...employeeSettings}>
							{employeData?.map((item, index) => (
								<div key={index} className="mx-3">
									<div
										className="card mx-3 bg-transparent"
										style={{ width: "16rem" }}
									>
										<img
											src={item?.image}
											className="card-img-top"
											alt={item?.name || "Employee"}
											loading="lazy"
											style={{
												objectFit: "cover",
												height: "200px",
												width: "100%",
												borderRadius: "5px",
												cursor: "pointer	",
											}}
										/>
										<div className="card-body">
											<h5 className="card-title">{item?.name || "Employee"}</h5>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div> */}

					<section className="moonscape-team">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="divider-wrapper">
										<div className="divider-line"></div>
									</div>
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-lg-12">
									<div className="teams-main-heading-wrapper">
										<h2>Moonscape Team Members</h2>
									</div>
								</div>
							</div>
							<div className="row">
								<Slider {...employeeSettings}>
									{aboutTeam?.map((item, index) => (
										<div
											className="col-lg-3 slider-item" // Add a class for the slide items
											key={index}
										>
											<div className="team-card-wrapper">
												<div className="team-img-wrapper">
													<figure>
														<img
															src={item?.image_url}
															className="card-img-top"
															alt={item?.name || "Employee"}
															loading="lazy"
														/>
													</figure>
												</div>
												<div className="team-content-wrapper">
													<h4>{item?.name || "Employee"}</h4>
													<p
														dangerouslySetInnerHTML={{
															__html: item?.description,
														}}
													></p>
												</div>
											</div>
										</div>
									))}
								</Slider>
							</div>
						</div>
					</section>
				</div>

				<div>
					<Slider {...settings}>
						{companyLogos?.map((item, index) => (
							<div className="logo_slider_inner" key={index}>
								<a href={item?.url} target="_blank">
									<figure>
										<img
											src={item?.image_url}
											alt=""
											srcset=""
											className="img-fluid"
										/>
									</figure>
								</a>
							</div>
						))}
					</Slider>
				</div>
			</section>
			{/* MOONSCAPE team ends here */}

			<Footer />
		</>
	);
};

export default About;
