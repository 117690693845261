import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
	BlogImg1,
	BlogImg2,
	BlogImg3,
	RecentNews1,
	backArrow,
} from "../../constant";
import Backhistory from "../../components/Backhistory";
import "../../assets/css/blog.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useArtistblogdetailsContentQuery, usePostCommentsApiMutation } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import moment from "moment";
import Loader from "../../components/Loader";
import { useQuery } from "../../components/Query";
import swal from "sweetalert";
const BlogDetail = () => {
	// comment 
	const [comment, setComment] = useState("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [postCommentsData, response, isSuccess, isError] = usePostCommentsApiMutation();

	const handlePostComment = (e) => {
		e.preventDefault();

		if (!comment.trim()) {
			setError("Comment cannot be empty");
			return;
		}

		const formData = new FormData();
		formData.append("blog", BlogDetail?.id);
		formData.append("text", comment);

		setLoading(true);
		postCommentsData(formData)
			.unwrap()
			.then((res) => {
				setComment("");
				setLoading(false);
				setError(null);
				refetch(); // Refetch the comments to update the UI
			})
			.catch((err) => {
				setLoading(false);
			});
	};


	useEffect(() => {

		if (response?.isSuccess) {
			swal("Successfully", response?.data?.message, "success");

		}

		if (response?.error?.data?.errors?.[0]?.message) {
			swal("Authentication Error", "Please log in first.", "error");
		}


	}, [isSuccess, isError, response]);


	let query = useQuery();
	let slugs = query.get("slug");
	const navigate = useNavigate();
	const { data, isLoading, refetch } = useArtistblogdetailsContentQuery(slugs);
	const BlogDetail = data?.data;
	

	const comments = data?.data?.comments;

	const back = () => {
		window.history.back();
	};
	useEffect(() => {
		if (slugs === null || slugs === "") {
			return navigate("/blogs");
		}
	}, [slugs]);
	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Blog Sec Start Here */}
			<section className="blog_sec blog-detail">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Blog</h1>
						</div>
						<Backhistory classNa={"back_icon"} />
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="all_blogs">
								<div className="blog_box">
									<div className="blog-img">
										<figure>
											<img
												src={BlogDetail?.image_url}
												alt="Blog Detail Image"
												className="img-fluid"
											/>
										</figure>
										<div className="date">
											<h4>{moment(BlogDetail?.created_at).format("Do MMM")}</h4>
										</div>
									</div>
									<div className="blog-detail">
										<div className="posted">
											<h5>
												{BlogDetail?.addedby?.name} /{" "}
												<span> {BlogDetail?.category?.name}</span>{" "}
											</h5>
										</div>
										<div className="blog-description">
											<p
												dangerouslySetInnerHTML={{
													__html: BlogDetail?.description,
												}}
											></p>


											{/* <div class="mb-3">
												<label htmlFor="comment" className="post_Comment">
													Post Your Comments <span>*</span>
												</label>												<textarea
													name="comment"
													value={comment}
													onChange={(e) => seComment(e.target.value)}
													cols="30"
													rows="10"
													className="form-control"
													id="description"
												></textarea>

												<div className="d-flex justify-content-lg-end justify-content-center">
													<button onClick={(e) => hanldePostComment(e)} className="btn">
														Submit
													</button>
												</div>
											</div> */}
											<form onSubmit={handlePostComment}>
												<label htmlFor="comment" className="post_Comment">
													Post Your Comments <span>*</span>
												</label>
												<textarea
													value={comment}
													onChange={(e) => setComment(e.target.value)}
													placeholder="Write a comment"
													cols="30"
													rows="10"
													className="form-control"
													id="description"
													style={{ background: 'transparent', color: 'white' }}
												/>
												{error && <p style={{ color: 'red' }}>{error}</p>}  {/* Show error if present */}
												<div className="d-flex justify-content-lg-end justify-content-center">

													<button type="submit" disabled={loading} className="btn">
														{loading ? (
															<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
														) : (
															"Send Comment"
														)}
													</button>
												</div>
											</form>



											<div style={{ width: "100%", margin: "20px auto", padding: "20px", border: "1px solid #ddd", borderRadius: "8px" }}>
												<h1 style={{ textAlign: "start" }}>Your Comments</h1>
												{comments && comments.length > 0 ? (
													comments.map((item, i) => (
														<div
															key={i}
															style={{
																display: "flex",
																alignItems: "flex-start",
																marginBottom: "15px",
																padding: "15px",
																borderRadius: "8px",
																boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
															}}
														>

															<img
																src={item.user?.image_url}
																alt="avatar"
																style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight: "15px" }}
															/>

															<div>
																<div style={{ marginBottom: "5px" }}>
																	<strong>{item.user?.f_name}</strong> <span style={{ color: "#888", fontSize: "12px" }}>{item.time}</span>
																</div>


																<p style={{ margin: 0, fontSize: "16px", color: "#f2f2f2" }}>{item?.text}</p>


															</div>
														</div>
													))
												) : (
													<p style={{ textAlign: "start", color: "#999", fontSize: "16px" }}>No comments found</p>
												)}
											</div>



											<div className="go-to-previous-page">
												<button onClick={back} className="btn">

													<i className="fa fa-angle-left"></i> Go Back
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Blog Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default BlogDetail;
